import './index.css'

function Footer(){
    return(
        <div className='footer-container'>
            <h3>dhawalsharma26@icloud.com</h3>
            <h3>514-569-1098</h3>
            <h3>©dhawal_sharma_2024</h3>
        </div>
    )
}
 export default Footer;